import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/hoe-werkt-imodium-instant/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/comment-agit-imodium/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="cf718407678e1eea2d3e62b60ddc11f473866d1a"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="active first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview">
                <a href="/fr/comment-agit-imodium/"> Comment agit IMODIUM®?</a>
              </li>
              <li className="last">
                <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                  La substance active : lopéramide
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
				<a href="/fr/medicaments-imodium/">
                <h4>Quel IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Range_packshot_NL_small.JPG"
                    width="170"
                    height="102"
                    alt="Quel IMODIUM®?"
                  />
                </div>
                <p>
                  Cherchez quel <strong>IMODIUM®</strong> est le plus adapté
                  pour vous.&nbsp;
                </p>
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-4">
            <h1>Comment agit IMODIUM® contre les symptômes de la diarrhée?</h1>
            <script src="/assets/js/vendor/swfobject.js" />
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html:
                  '\nvar flashvars = {\nterritory: "fr",\nxmlPath: "/assets/xml/how_it_works_localisation.xml"\n};\nvar params = {};\nvar attributes = {};\nswfobject.embedSWF("/assets/files/pages/bowel.swf", "how-it-works-bowel", "708", "294", "9.0.0", "expressInstall.swf", flashvars, params, attributes);\n'
              }}
            />
            {/* <div
              id="how-it-works-bowel"
              style={{
                height: "294px",
                "max-width": "100%"
              }}
            >
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div> */}
            <video src="/assets/video/Imodium_03_2023_diarree_FR_HD.mp4" width="100%" height="240" controls />
            <div className="intro row">
              <div className="three columns">
                <h2>Comment survient la diarrhée ?</h2>
              </div>
              <div className="nine columns">
                <p>
                  Lorsque l’absorption de nutriments et d’eau par l’intestin est
                  empêchée et qu’en même temps, la sécrétion de ces substances
                  par le corps augmente dans l’intestin, le volume contenu dans
                  celui-ci s’accroît et le transit intestinal (motilité)
                  augmente. Ceci conduit à une émission plus fréquente de selles
                  liquides et aqueuses, connue sous le nom de diarrhée.
                </p>
              </div>
            </div>
            <p>
              Visionnez l'animation ci-dessus pour comprendre l'action de
              IMODIUM sur&nbsp; la diarrhée par le lopéramide, son principe
              actif.
            </p>
            <h4>
              IMODIUM<sup>® </sup>en cas de diarrhée
            </h4>
            <p>
              Grâce à la substance active{" "}
              <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                lopéramide
              </a>
              , IMODIUM<sup>®</sup> Instant réduit l’activité intestinale
              excessive. Le
              contenu de l’intestin reste ainsi plus longtemps en contact avec
              la muqueuse intestinale, ce qui permet d’absorber davantage de
              liquide de l’intestin.
            </p>
            <p>
              De cette manière, on peut traiter les symptômes de la diarrhée.
              Imodium<sup>®</sup>&nbsp;contribue à vaincre rapidement et
              efficacement la diarrheé et normalise la sécrétion accrue de liquides et d'électrolytes dans l'intestin. La déshydratation résultant de la perte de liquides et
              d’électrolytes, qui affaiblit inutilement le corps et ralentit la
              guérison, est ainsi évitée.
            </p>
            <div>
              <sup>(SCHILLER L.R. Aliment Pharmacol Ther 1995; 9: 87-106)</sup>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
